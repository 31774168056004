import React from 'react';
import {
  Button,
  Slide,
  Dialog,
  DialogActions,
  Box,
  DialogContent,
  Typography,
} from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const Confirma = (props) => {
  const {
    open,
    setOpen,
    titulo = '¿Deseas crear una cuenta de estudiante con este correo?',
    action,
    extraContent,
    confirmButtonText = 'Sí',
    cancelButtonText = 'Cancelar',
  } = props;

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        padding='24px 48px'
        flexDirection='column'
        gap='16px'>
        <ErrorOutline style={{ color: '#21594F', fontSize: '2rem' }} />
        <Typography
          variant='h5'
          style={{ fontWeight: 'bold', textAlign: 'center' }}>
          {titulo}
        </Typography>
      </Box>
      {extraContent && (
        <DialogContent style={{ padding: '16px 48px 24px' }}>
          {extraContent}
        </DialogContent>
      )}
      <DialogActions style={{ padding: '24px 48px', background: '#F7F7F7' }}>
        <Button onClick={handleClose} color='secondary' variant='outlined'>
          {cancelButtonText}
        </Button>
        <Button onClick={action} color='secondary' variant='contained'>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirma;
