import { useContext } from 'react';
import { ResourcesContext } from 'state/ResourcesProvider';
import { handleAlerts } from 'utils';
import { MODIFY_STUDENT } from 'utils/api';

export const useModifyStudent = () => {
  const { errorMessage } = handleAlerts();
  const { state, dispatch } = useContext(ResourcesContext);

  const modifyStudent = async (user, data) => {
    try {
      await MODIFY_STUDENT(user, data);
      dispatch({
        type: 'SET_ESTUDIANTE',
        payload: { ...state.estudiante, ...data },
      });
    } catch (error) {
      errorMessage(error.response.data?.message || error?.message);
    }
  };

  return { modifyStudent };
};
